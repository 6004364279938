import { useMutation } from "react-query";
import { apiUrls } from "../../api-urls";
import { TStudentValues } from "../../../form/initial-value/addStudent.values";
import { request } from "../../../services/axios.service";

const updateEmployee = async (data: TStudentValues) => {
  const response: TServerResponse<unknown,unknown> = await request({
    url: apiUrls.UPDATE_EMPLOYEE,
    method: "POST",
    data: data,
  });
  return response;
};

export const useUpdateEmployee = () => {
  return useMutation(updateEmployee);
};
