import { useQuery } from "react-query";
import { apiUrls } from "../api-urls";
import { request } from "../../services/axios.service";

const getWorkLog = async (date: Date, type: string | null, id?: string) => {

  const response: TServerResponse<TNote[], unknown> = await request({
    url: apiUrls.GET_NOTE,
    method: "GET",
    params: {
      date: date,
      userId: id,
      type,
    },
  });
  return response;
};

export const useGetWorkLog = (date: Date, type: string | null, id?: string) => {
  return useQuery(["user", "get-worklog", date, id], () =>
    getWorkLog(date, type, id)
  );
};
