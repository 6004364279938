import { MonthPickerInput } from "@mantine/dates";
import AttendanceList from "./component/AttendanceList";
import { Box, Center, Flex, Group, Loader, Select, Title } from "@mantine/core";

import { useGetAllEmployeeAttendance } from "../../hooks/all-student-attendance/query/useAllEmployeeAttendance.query";
import React, { useMemo, useState } from "react";
import { CONSTANTS } from "../../constant";
import AttendanceExport from "./component/data/AttendanceExport";
import { useGetAllStudent } from "../../hooks/students/query/getAllStudent.query";
const AllStudentAttendance: React.FC = () => {
  const [month, setMonth] = useState<Date>(new Date());
  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState<TPageData>({
    total: 0,
  });
  const { data: studentsData, isLoading: studentLoading } = useGetAllStudent({
    blocked: false,
  });
  const [selectedStudent, setSelectedStudent] = useState<string>("All");

  const students = useMemo(() => {
    if (!studentLoading && studentsData?.data) {
      return (studentsData.data as []).map((student: any) => ({
        value: student["_id"],
        label: student["name"],
      }));
    }
    return [];
  }, [studentLoading, studentsData]);

  const { data, isLoading } = useGetAllEmployeeAttendance({
    paging: {
      itemPerPage: CONSTANTS.PAGE_LIMIT,
      page: activePage,
    },
    date: month,
    studentId: selectedStudent,
  });

  const attendance: TAttendanceData[] = useMemo(() => {
    if (!isLoading && data?.data) {
      if (data.pageData) {
        setPagedData(data.pageData);
      }
      return data.data;
    } else {
      return [] as TAttendanceData[];
    }
  }, [isLoading, data, selectedStudent]);
  if (isLoading) {
    return (
      <Center h={"70vh"}>
        <Loader />
      </Center>
    );
  }

  return (
    <Box>
      <Box>
        <Flex
          mih={50}
          gap="md"
          justify="space-between"
          align="center"
          direction="row"
        >
          <Group>
            <Title order={3} weight={500} c="gray">
              Time Sheets
            </Title>
            <MonthPickerInput
              placeholder={month.toDateString()}
              value={month}
              onChange={(val) => {
                if (val) {
                  setMonth(val);
                }
              }}
            />
          </Group>
          <Group>
            <Select
              data={[{ value: "All", label: "All" }, ...students]}
              defaultValue={"All"}
              onChange={(value) => {
                if (value) setSelectedStudent(value);
              }}
            />
            <AttendanceExport date={month} studentId={selectedStudent} />
          </Group>
        </Flex>
      </Box>
      <AttendanceList
        pagedData={pagedData}
        setActivePage={setActivePage}
        loading={isLoading}
        attendance={attendance}
      />
    </Box>
  );
};
export default AllStudentAttendance;
