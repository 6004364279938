export const COLORS = {
  ABSENT: "#fa5252",
  ABSENT_BG: "#fa52520f",
  INITIATE: "#000",
  INITIATE_SECONDARY: "#0097ED",
  INITIATE_SECONDARY_BG: "#0097ed0f",
  ON_TIME: "#40C057",
  ON_TIME_BG: "#40C0570f",
  LATE: "#ff841d",
  LATE_BG: "#ff841d0f",
  LEAVE: "#e64980",
  LEAVE_BG: "#e649800f",
};
