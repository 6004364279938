import React, { useEffect, useMemo, useState } from "react";
import { useGetMonthlyLeaves } from "../../../hooks/leave/query/getMonthlyLeaves.query";
import {
  Box,
  Center,
  Flex,
  Group,
  Loader,
  Pagination,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { CustomTable } from "./CustomTable";
import { IconSearch, IconPlus } from "@tabler/icons-react";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import ThemeButton from "../../../component/button/ThemeButton";
import CategoryModal from "./CategoryModal";

interface TAllRequest {
  filter?: string;
}
interface IFormValues {
  fromDate: Date | null;
  toDate: Date | null;
}

export const AllRequest: React.FC<TAllRequest> = (props) => {
  const [activePage, setActivePage] = useState(1);
  const [visible, setVisible] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [date] = useState(new Date());
  const [pagedData, setPagedData] = useState<TPageData>({
    total: 0,
  });
  const form = useForm<IFormValues>({
    initialValues: {
      fromDate: null,
      toDate: null,
    },
  });
  const {
    data: getMonthlyLeavesData,
    isLoading: isMonthlyLeavesLoading,
    refetch,
    isRefetching,
  } = useGetMonthlyLeaves({
    paging: { itemPerPage: 8, page: activePage },
    date: date,
    status: props.filter ?? undefined,
    search: search,
    range: {
      from: form.values.fromDate,
      to: form.values.toDate,
    },
  });

  const monthlyLeaves: TLeave[] = useMemo(() => {
    if (!isMonthlyLeavesLoading && getMonthlyLeavesData?.data) {
      if (getMonthlyLeavesData.pageData) {
        setPagedData(getMonthlyLeavesData.pageData);
      }
      if (props.filter) {
        const filterData = getMonthlyLeavesData.data.filter(
          (leave) => leave.status === props.filter
        );
        return filterData;
      }
      return getMonthlyLeavesData.data;
    } else {
      return [] as TLeave[];
    }
  }, [getMonthlyLeavesData, isMonthlyLeavesLoading]);

  useEffect(() => {
    refetch();
  }, [search, activePage, form.values.fromDate, form.values.toDate]);

  const handleSearchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearch(event.target.value);
    setActivePage(1);
  };

  return (
    <Flex direction={"column"}>
      <CategoryModal onClose={() => setVisible(false)} opened={visible} />
      <Flex justify={"space-between"} align={"flex-start"}>
        <Flex align="center" mb="md">
          <TextInput
            placeholder="Search by student name or email..."
            icon={<IconSearch size={16} />}
            value={search}
            onChange={handleSearchChange}
            style={{ marginRight: 10 }}
          />
          <Group mx={10}>
            <Text>From</Text>
            <DatePickerInput
              miw={100}
              placeholder="Pick From date"
              {...form.getInputProps("fromDate")}
              clearable
            />
          </Group>
          <Group mx={10}>
            <Text>To</Text>
            <DatePickerInput
              miw={100}
              placeholder="Pick To date"
              {...form.getInputProps("toDate")}
              clearable
            />
          </Group>
        </Flex>
        <Box onClick={() => setVisible(true)}>
          <ThemeButton
            title="Add Category"
            mr={15}
            leftIcon={<IconPlus size={20} />}
          />
        </Box>
      </Flex>

      {!isMonthlyLeavesLoading && !isRefetching ? (
        monthlyLeaves.length === 0 ? (
          <Center mih={"50vh"}>
            <Title order={2} tt={"capitalize"}>
              No {props.filter ?? ""} Request
            </Title>
          </Center>
        ) : (
          <>
            <CustomTable data={monthlyLeaves} itemPerPage={8} />
            {pagedData.total > 8 ? (
              <Pagination
                color={"pink"}
                my={10}
                total={Math.ceil(pagedData.total / 8)}
                value={activePage}
                onChange={setActivePage}
              />
            ) : null}
          </>
        )
      ) : (
        <Center mih={"60vh"}>
          <Loader type="bar" />
        </Center>
      )}
    </Flex>
  );
};
