import React, { useEffect, useState } from "react";
import InputField from "../../component/form/input-field/InputField";
import { useForm, yupResolver } from "@mantine/form";
import ThemeButton from "../../component/button/ThemeButton";
import {
  Box,
  createStyles,
  Text,
  Title,
  Select,
  Button,
  Group,
  Flex,
  HoverCard,
} from "@mantine/core";
import { useUpdateSettings } from "../../hooks/settings/mutation/updateSettings.mutation";
import { TSettingsValues } from "../../form/initial-value/settings.values";
import { useGetSettings } from "../../hooks/settings/query/getSettings.query";
import { notifications } from "@mantine/notifications";
import { initialValue } from "../../form/initial-value";
import { validations } from "../../form/validation";
import { useGenerateWorkApiTokenMutation } from "../../hooks/settings/mutation/generateWorkApiToken";
import { queryClient } from "../..";
import { getFirstTwoSaturdayOfYear } from "../../utils/holiday/getFirstTwoSaturdays";

const Settings = () => {
  const { getInputProps, setValues, onSubmit, setFieldValue, values } = useForm(
    {
      initialValues: initialValue.settingsIniValues,
      validateInputOnBlur: true,
      validateInputOnChange: true,
      validate: yupResolver(validations.settings),
    }
  );

  const { classes } = useStyles();
  const { mutateAsync: updateSettings } = useUpdateSettings();
  const { mutateAsync: GenerateWorkApiToken } =
    useGenerateWorkApiTokenMutation();
  const { data: settingsData, isLoading: settingsLoading } = useGetSettings();
  const { first, second } = getFirstTwoSaturdayOfYear(new Date().getFullYear());

  const [selectData, setSelectData] = useState([
    { value: first.toISOString(), label: first.toDateString() },
    { value: second.toISOString(), label: second.toDateString() },
  ]);
  useEffect(() => {
    if (!settingsLoading && settingsData && settingsData.data) {
      if (settingsData.data.referenceSaturday) {
        settingsData.data.referenceSaturday = new Date(
          settingsData.data.referenceSaturday
        );
        const referenceSaturdayDate = new Date(
          settingsData.data.referenceSaturday
        );

        const isSaturdayInSelect = selectData.some(
          (item) => item.value === referenceSaturdayDate.toISOString()
        );

        if (!isSaturdayInSelect) {
          setSelectData((prevSelectData) => [
            {
              value: referenceSaturdayDate.toISOString(),
              label: referenceSaturdayDate.toDateString(),
            },
            ...prevSelectData,
          ]);
        }
      }
      setValues(settingsData.data);
    } else {
      setValues(initialValue.settingsIniValues);
    }
  }, [settingsData, setValues, settingsLoading]);

  const handleUpdate = async (values: TSettingsValues) => {
    try {
      const res = await updateSettings(values);
      if (res.status === "success") {
        notifications.show({
          color: "green",
          message: res.message,
        });
        setFieldValue("password", "");
      } else {
        notifications.show({
          color: "red",
          message: res.message,
        });
      }
    } catch (error) {
      notifications.show({
        color: "red",
        message: (error as Error).message,
      });
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      notifications.show({
        color: "green",
        message: "Copied to clipboard!",
      });
    });
  };

  const handleGenerateWorkApiToken = async () => {
    const res = await GenerateWorkApiToken();

    if (res.status === "success") {
      await queryClient.invalidateQueries(["admin", "all-settings"]);
      notifications.show({
        message: "Token Generated Successfully",
        color: "green",
      });
    }
  };

  return (
    <Box>
      <form onSubmit={onSubmit(handleUpdate)}>
        <Box className={classes.container}>
          <Title order={4}>Check Time Details</Title>
          <Box className={classes.mainBox}>
            <Box className={classes.input}>
              <InputField
                label="Check In Time"
                getInputProps={getInputProps}
                name="checkIn"
              ></InputField>
            </Box>
            <Box className={classes.input}>
              <InputField
                label="Check Out Time"
                getInputProps={getInputProps}
                name="checkOut"
              ></InputField>
            </Box>
          </Box>
        </Box>
        <Box className={classes.container}>
          <Title order={4}>QR Panel Details</Title>
          <Box className={classes.mainBox}>
            <Box className={classes.input}>
              <InputField
                label="QR Panel User Name"
                getInputProps={getInputProps}
                name="userName"
              ></InputField>
            </Box>
            <Box className={classes.input}>
              <InputField
                label="QR Panel Password"
                getInputProps={getInputProps}
                name="password"
              ></InputField>
            </Box>
          </Box>
        </Box>
        <Box className={classes.container}>
          <Title order={4}>App Build Details</Title>
          <Box className={classes.mainBox}>
            <Box className={classes.input}>
              <InputField
                label="App Build Number"
                getInputProps={getInputProps}
                name="buildNumber"
              ></InputField>
            </Box>
            <Box className={classes.input}>
              <InputField
                label="Update screen title"
                getInputProps={getInputProps}
                name="updateTitle"
              ></InputField>
            </Box>
            <Box className={classes.input}>
              <InputField
                label="Update screen message"
                getInputProps={getInputProps}
                name="updateMessage"
              ></InputField>
            </Box>
          </Box>
          <Box className={classes.mainBox}></Box>
        </Box>
        <Box className={classes.container}>
          <Title order={4}>Holiday Details</Title>
          <Box className={classes.mainBox}>
            <Box className={classes.input}>
              <HoverCard width={280} shadow="md" position="right">
                <HoverCard.Target>
                  <Select
                    data={selectData}
                    label="Reference Alternate Saturday"
                    placeholder={"Select Reference for Alternate Saturday"}
                    value={values.referenceSaturday?.toISOString()}
                    onChange={(value) => {
                      if (value)
                        setFieldValue("referenceSaturday", new Date(value));
                    }}
                  />
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm">
                    Please note that changing the reference Saturday will only
                    be effecting when changes end of the month.
                  </Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Box>
          </Box>
          <Box className={classes.mainBox}></Box>
        </Box>
        <Box className={classes.container}>
          <Title order={4}>Api Tokens</Title>
          <Box className={classes.tokenMainBox}>
            <Box className={classes.tokenInput}>
              <Text>Work Api Token</Text>
              <Flex className={classes.mainBox}>
                <Text className={classes.tokenContainer}>
                  {values.ApiTokens.workApiToken}
                </Text>
                <Button
                  onClick={() => handleCopy(values.ApiTokens.workApiToken)}
                  className={classes.copyButton}
                >
                  Copy
                </Button>
                <Button
                  onClick={handleGenerateWorkApiToken}
                  className={classes.copyButton}
                >
                  Regenrate Token
                </Button>
              </Flex>
            </Box>
          </Box>
        </Box>

        <Box className={classes.button}>
          <ThemeButton type="submit" title="Update" w={300}></ThemeButton>
        </Box>
      </form>
    </Box>
  );
};

const useStyles = createStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    margin: 10,
  },
  mainBox: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  button: {
    paddingTop: "37px",
    justifyContent: "center",
    display: "flex",
  },
  input: {
    width: "30%",
    marginLeft: 10,
  },
  tokenMainBox: {
    display: "flex",
    width: "100%",
  },
  tokenInput: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-end",
  },
  tokenContainer: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: "#eaeaea",
    margin: 10,
    border: "1px solid ",
    textAlign: "center",
  },
  copyButton: {
    backgroundColor: "#ff008a",
    color: "white",
    margin: 5,
    marginTop: 10,
    ":hover": {
      backgroundColor: "#d50075",
    },
  },
}));

export default Settings;
