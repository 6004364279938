import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const GenerateWorkApiToken = async () => {
  const response = await request({
    url: apiUrls.GENRATE_WORK_API_TOKEN,
    method: "POST",
  });
  return response;
};

export const useGenerateWorkApiTokenMutation = () => {
  return useMutation(GenerateWorkApiToken);
};
