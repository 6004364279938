export type TSettingsValues = {
  checkIn: string;
  checkOut: string;
  userName: string;
  password?: string;
  buildNumber: string;
  updateTitle: string;
  updateMessage: string;
  referenceSaturday?: Date;
  ApiTokens: {
    workApiToken: string;
  };
};

export const settingsIniValues: TSettingsValues = {
  checkIn: "",
  checkOut: "",
  userName: "",
  buildNumber: "",
  updateMessage: "",
  updateTitle: "",
  referenceSaturday: undefined,
  ApiTokens: {
    workApiToken: "No Token Please Generate One",
  },
};
