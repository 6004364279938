import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUserData {
  name: string;
}
const initialState: IUserData = {
  name: "",
};

const userDataSlice = createSlice({
  name: "user data",
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<IUserData>) {
      state.name = action.payload.name;
    },
  },
});

export const { setUserData } = userDataSlice.actions;
export default userDataSlice.reducer;
