import { useQuery } from "react-query";
import { request } from "../../services/axios.service";
import { apiUrls } from "../api-urls";
const profile = async () => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.GET_PROFILE,
    method: "GET",
  });
  if (response.status === "success" && window.location.pathname === "/login") {
    window.location.href = "/";
  }
  return response;
};

export const useGetProfile = () => {
  return useQuery({
    queryKey: ["get-profile"],
    queryFn: () => profile(),
  });
};
