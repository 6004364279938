import {
  ActionIcon,
  Badge,
  Center,
  Group,
  Loader,
  Table,
  Tooltip,
} from "@mantine/core";
import { IconCheckbox, IconSquareRoundedX } from "@tabler/icons-react";
import React, { useCallback, useState } from "react";
import { getDateRange } from "../../../utils/leaves/getDateRange";
import { useUpdateRequestMutation } from "../../../hooks/leave/mutation/updateRequest";
import { notifications } from "@mantine/notifications";
import { queryClient } from "../../..";
import ConfirmationModal from "./ConfirmationModal";

interface CustomTableProps {
  data: TLeave[];
  itemPerPage: number;
  action?: boolean;
}

export const CustomTable: React.FC<CustomTableProps> = ({
  data,
  itemPerPage,
  action = false,
}) => {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [title, setTitle] = useState<string | null>(null);
  const { isLoading: isUpdateLoading, mutateAsync: updateRequest } =
    useUpdateRequestMutation();

  const handleRowClick = (id: string) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  const handleFormSubmit = useCallback(
    async (status: string, leaveId: string) => {
      const res = await updateRequest({ status: status, leaveId: leaveId });
      if (res.status === "success") {
        await queryClient.invalidateQueries("leaves-monthly");
        await queryClient.invalidateQueries("pending-request");
        notifications.show({
          color: "green",
          message: res.message,
        });
      }
    },
    [updateRequest]
  );

  function getStatusColor(status: string) {
    return status === "rejected"
      ? "red"
      : status === "accepted"
      ? "green"
      : status === "pending"
      ? "yellow"
      : "gray";
  }

  const emptyRows = itemPerPage - data.length;

  return isUpdateLoading ? (
    <Center mih={"60vh"}>
      <Loader type="bar" />
    </Center>
  ) : (
    <Table
      horizontalSpacing="xl"
      verticalSpacing="md"
      highlightOnHover
      sx={{
        width: "100%",
        margin: "auto",
        border: "2px solid #ddd",
        borderRadius: "8px",
      }}
    >
      <thead>
        <tr>
          <th style={{ textAlign: "center" }}>Student Name</th>
          <th style={{ textAlign: "center" }}>Email</th>
          <th style={{ textAlign: "center" }}>Category</th>
          <th style={{ textAlign: "center" }}>From</th>
          <th style={{ textAlign: "center" }}>To</th>
          <th style={{ textAlign: "center" }}>Type</th>
          <th style={{ width: "200px", textAlign: "center" }}>Reason</th>
          <th style={{ textAlign: "center" }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map((leave) => {
          const { from, to } = getDateRange(leave.dates);
          const isExpanded = expandedRow === leave._id;

          return (
            <tr
              key={leave._id}
              style={{
                backgroundColor: "white",
                cursor: "pointer",
                height: isExpanded ? "auto" : "50px",
              }}
              onClick={() => handleRowClick(leave._id)}
            >
              <ConfirmationModal
                visible={visible}
                onClose={() => setVisible(false)}
                title={title}
                onContinue={async () => {
                  if (title) await handleFormSubmit(title, leave._id);
                }}
              />
              <td style={{ textAlign: "center" }}>{leave.studentId.name}</td>
              <td style={{ textAlign: "center" }}>{leave.studentId.email}</td>
              <td style={{ textTransform: "capitalize", textAlign: "center" }}>
                {leave.categoryId?.name ?? "Deleted Category"}
              </td>
              <td style={{ textAlign: "center" }}>{from}</td>
              <td style={{ textAlign: "center" }}>{to}</td>
              <td style={{ textTransform: "capitalize", textAlign: "center" }}>
                {leave.type}
              </td>
              <td
                style={{
                  maxWidth: "250px",
                  minWidth: "250px",
                  whiteSpace: isExpanded ? "normal" : "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign:
                    isExpanded && leave.reason.length > 30 ? "left" : "center",
                }}
              >
                {leave.reason}
              </td>
              {action === true ? (
                <td align="center">
                  <Group noWrap>
                    <Tooltip label="Accept">
                      <ActionIcon
                        variant="outline"
                        color="green"
                        onClick={async (e) => {
                          e.stopPropagation();
                          setTitle("accepted");
                          setVisible(true);
                        }}
                      >
                        <IconCheckbox />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Reject">
                      <ActionIcon
                        variant="outline"
                        color="red"
                        onClick={async (e) => {
                          e.stopPropagation();
                          setTitle("rejected");
                          setVisible(true);
                        }}
                      >
                        <IconSquareRoundedX size={20} />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                </td>
              ) : (
                <td
                  style={{
                    textTransform: "capitalize",
                    alignItems: "center",
                  }}
                >
                  <Badge color={getStatusColor(leave.status)} variant="filled">
                    {leave.status}
                  </Badge>
                </td>
              )}
            </tr>
          );
        })}

        {emptyRows > 0 &&
          Array.from({ length: emptyRows }).map((_, index) => (
            <tr key={`empty-${index}`} style={{ height: "56px" }}>
              <td
                colSpan={8}
                style={{ textAlign: "center", color: "#aaa" }}
              ></td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
