import React from "react";
import { IconList } from "@tabler/icons-react";
import { Button } from "@mantine/core";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { notifications } from "@mantine/notifications";
interface ExcelExportProps {
  ExcelData: TAttendanceData[] | undefined;
  fileName: string;
  loading?: boolean;
}
const ExcelExport: React.FC<ExcelExportProps> = ({
  ExcelData,
  fileName,
  loading,
}) => {
  const exportToExcel = (ExcelData: TAttendanceData[], fileName: string) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(ExcelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      loading={loading}
      disabled={loading}
      style={{
        color: "#ff008a",
        backgroundColor: "#ffe5f2",
      }}
      leftIcon={<IconList size={17} />}
      onClick={() => {
        if (ExcelData) {
          if (ExcelData.length === 0) {
            notifications.show({
              title: "No Attendance Available",
              message: "Corresponding student have no attendance",
              color: "orange",
            });
          } else {
            exportToExcel(ExcelData, fileName);
          }
        } else {
          notifications.show({
            title: "Unable to Fetch Data",
            message: "Attendacne Data Not Available",
            color: "red",
          });
        }
      }}
    >
      Export to Excel
    </Button>
  );
};
export default ExcelExport;
