import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const DeleteCategory = async (id: string) => {
  const response = await request({
    url: apiUrls.DELETE_CATEGORY,
    method: "PUT",
    data: {
      id: id,
    },
  });
  return response;
};

export const useDeleteCategoryMutation = () => {
  return useMutation(DeleteCategory);
};
