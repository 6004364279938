import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
import { TStudentValues } from "../../../form/initial-value/addStudent.values";

const AddEmployee = async (data: TStudentValues) => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.ADDEMPLOYEE,
    method: "POST",
    data: data,
  });
  return response;
};

export const useAddEmployeeMutation = () => {
  return useMutation(AddEmployee);
};
