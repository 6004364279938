import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getCategory = async () => {
  const response = await request({
    url: apiUrls.GET_CATEGORY,
    method: "GET",
  });
  return response;
};

export const useGetCategories = () => {
  return useQuery(["categories"], () => getCategory());
};
