export const getFirstTwoSaturdayOfYear = (year: number) => {
  const first = new Date(year, 0, 1);

  const dayOfWeek = first.getDay();

  const daysUntilSaturday = (6 - dayOfWeek + 7) % 7;

  first.setDate(first.getDate() + daysUntilSaturday);
  const second = new Date(first);
  second.setDate(first.getDate() + 7);

  return { first, second };
};
