import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
interface TAdd {
  name: string;

}

const AddCategory = async (data: TAdd) => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.ADD_CATEGORY,
    method: "POST",
    data: {
      name: data.name,
    },
  });
  return response;
};

export const useAddCategoryMutation = () => {
  return useMutation(AddCategory);
};
