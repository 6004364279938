import {
  Box,
  Card,
  Center,
  Divider,
  Group,
  Indicator,
  Text,
  createStyles,
} from "@mantine/core";
import moment from "moment";
import React, { FC, memo, useMemo } from "react";
import { totalTime } from "../../utils/attendance/totalTime";
import { COLORS } from "../../assets/colors/index.colors";

interface IAttendanceCard {
  data: TAttendanceData;
}

const AttendanceCard: FC<IAttendanceCard> = ({ data }) => {
  const { classes } = useStyles();

  // Helper function to get status color and classes

  const statusStyle = useMemo(() => {
    return getStatusStyle(data.status, classes);
  }, [data.status]);
  return (
    <Card
      shadow="md"
      radius="md"
      className={`${classes.card} ${statusStyle.cardStatus}`}
      padding="sm"
    >
      <Group style={{ display: "flex", justifyContent: "space-between" }}>
        <Text fz="md" fw={600} color="dark">
          {data.name}
        </Text>
        <Box className={`${classes.statusBox} ${statusStyle.statusClasses}`}>
          <Center>
            <Indicator
              inline
              size={8}
              position="middle-start"
              color={statusStyle.color}
            >
              <Text ml={10} size="xs" weight={500}>
                {data.status}
              </Text>
            </Indicator>
          </Center>
        </Box>
      </Group>
      <Divider my="sm" />
      <Group
        spacing="xl"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Text c="dimmed" weight={500} size="xs">
            In Time
          </Text>
          <Text fz="sm" weight={500}>
            {data.inTime ? moment(data.inTime).format("LT") : "---"}
          </Text>
        </Box>
        <Box>
          <Text c="dimmed" weight={500} size="xs">
            Out Time
          </Text>
          <Text fz="sm" weight={500}>
            {data.outTime ? moment(data.outTime).format("LT") : "---"}
          </Text>
        </Box>
        <Box>
          <Text c="dimmed" weight={500} size="xs">
            Total
          </Text>
          <Text fz="sm" weight={500}>
            {data.outTime ? totalTime(data.inTime, data.outTime) : "---"}
          </Text>
        </Box>
      </Group>
      <Divider my="sm" />
      <Group style={{ display: "flex", justifyContent: "space-between" }}>
        <Text fz="sm" fw={400} c="dimmed">
          In Note:
        </Text>

        <Text ml={10} size="xs" weight={500}>
          {data?.inNote}
        </Text>
      </Group>
      <Group style={{ display: "flex", justifyContent: "space-between" }}>
        <Text fz="sm" fw={400} c="dimmed">
          Out Note:
        </Text>

        <Text ml={10} size="xs" weight={500}>
          {data?.outNote}
        </Text>
      </Group>
    </Card>
  );
};

const getStatusStyle = (status: string, classes: { [key: string]: string }) => {
  switch (status) {
    case "absent":
      return {
        color: COLORS.ABSENT,
        cardStatus: `${classes.cardAbsent}`,
        statusClasses: `${classes.statusBoxAbsent}`,
      };
    case "on time":
      return {
        color: COLORS.ON_TIME,
        cardStatus: `${classes.cardOnTime}`,
        statusClasses: `${classes.statusBoxOnTime}`,
      };
    case "late":
      return {
        color: COLORS.LATE,
        cardStatus: `${classes.cardLate}`,
        statusClasses: `${classes.statusBoxLate}`,
      };
    case "leave":
      return {
        color: COLORS.LEAVE,
        cardStatus: `${classes.cardLeave}`,
        statusClasses: `${classes.statusBoxLeave}`,
      };
    default:
      return {
        color: COLORS.INITIATE_SECONDARY,
        cardStatus: `${classes.cardInitiate}`,
        statusClasses: `${classes.statusBoxInitiate}`,
      };
  }
};

const useStyles = createStyles(() => ({
  card: {
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: "4px",
    borderRadius: 0,
  },
  cardOnTime: {
    borderColor: COLORS.ON_TIME,
  },
  cardLate: {
    borderColor: COLORS.LATE,
  },
  cardLeave: {
    borderColor: COLORS.LEAVE,
  },
  cardAbsent: {
    borderColor: COLORS.ABSENT,
    color: "#868e96",
  },
  cardInitiate: {
    borderColor: COLORS.INITIATE_SECONDARY,
  },
  statusBox: {
    paddingBlock: "0.3rem",
    paddingInline: "0.7rem",
    borderRadius: "0.4rem",
  },
  statusBoxOnTime: {
    backgroundColor: COLORS.ON_TIME_BG,
    color: COLORS.ON_TIME,
  },
  statusBoxLate: {
    backgroundColor: COLORS.LATE_BG,
    color: COLORS.LATE,
  },
  statusBoxLeave: {
    backgroundColor: COLORS.LEAVE_BG,
    color: COLORS.LEAVE,
  },
  statusBoxAbsent: {
    backgroundColor: COLORS.ABSENT_BG,
    color: COLORS.ABSENT,
  },
  statusBoxInitiate: {
    backgroundColor: COLORS.INITIATE_SECONDARY_BG,
    color: COLORS.INITIATE_SECONDARY,
  },
  absentDimmed: {
    visibility: "hidden",
  },
}));

export default memo(AttendanceCard);
