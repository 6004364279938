import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getEmployeeAttendance = async (params: TEmployeeAttendanceParams) => {
  const response: TServerResponse<TAttendanceData[], TEmployeeHeaderData> =
    await request({
      url: apiUrls.EMPLOYEE_ATTENDANCE,
      method: "GET",
      params: {
        ...params.paging,
        date: params.date,
        studentId: params.studentId,
      },
    });
  return response;
};

export const useGetEmployeeAttendance = (params: TEmployeeAttendanceParams) => {
  return useQuery(["attendance", params], () => getEmployeeAttendance(params), {
    keepPreviousData: true,
  });
};
