import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllEmployeeAttendance = async (params: TAttendanceParams) => {
  const response: TServerResponse<TAttendanceData[], unknown> = await request({
    url: apiUrls.ALL_EMPLOYEE_ATTENDANCE,
    method: "GET",
    params: {
      ...params.paging,
      date: params.date,
      studentId: params.studentId === "All" ? undefined : params.studentId,
    },
  });
  return response;
};

export const useGetAllEmployeeAttendance = (params: TAttendanceParams) => {
  return useQuery(
    ["attendance", params],
    () => getAllEmployeeAttendance(params),
    { keepPreviousData: true }
  );
};
