import React, { useMemo, useState } from "react";
import {
  Modal,
  TextInput,
  Group,
  ActionIcon,
  Title,
  Loader,
  ScrollArea,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useAddCategoryMutation } from "../../../hooks/leave/mutation/addCategory.mutation";
import { notifications } from "@mantine/notifications";
import { CategoryTile } from "./CategoryTile";
import { useGetCategories } from "../../../hooks/leave/query/getCategory";
import { queryClient } from "../../..";

interface CategoryModalProps {
  opened: boolean;
  onClose: () => void;
}

const CategoryModal: React.FC<CategoryModalProps> = ({ opened, onClose }) => {
  const [categoryName, setCategoryName] = useState("");
  const { mutateAsync: addCategory } = useAddCategoryMutation();
  const { data, isLoading } = useGetCategories();

  const categories: [] = useMemo(() => {
    console.log(data);

    if (!isLoading && data.data) {
      return data.data;
    } else {
      return [];
    }
  }, [data, isLoading]);

  const handleAddCategory = async () => {
    if (categoryName.trim()) {
      const res = await addCategory({ name: categoryName.toLowerCase() });
      if (res.status === "success") {
        setCategoryName("");
        notifications.show({
          message: "Category Added Successfully",
          title: "Category Added",
        });
        await queryClient.invalidateQueries("categories");
      } else {
        notifications.show({
          message: res.message,
          title: res.title,
          color: "red",
        });
      }
    } else {
      notifications.show({
        title: "Please Add Name",
        message: "Name Cannot be empty",
        color: "red",
      });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Add Category"
      centered
      styles={{
        close: {
          color: "#ff008a",
          "&:hover": { backgroundColor: "#ff008a1a" },
        },
      }}
    >
      <Group align="end" noWrap>
        <TextInput
          label="Category Name"
          placeholder="Enter category name"
          value={categoryName}
          onChange={(event) => setCategoryName(event.currentTarget.value)}
          withAsterisk
          style={{ flexGrow: 1 }}
        />
        <ActionIcon
          size="lg"
          color="#ff008a"
          onClick={handleAddCategory}
          variant="filled"
          styles={{
            root: {
              backgroundColor: "#ff008a",
              "&:hover": { backgroundColor: "#ff008a" },
            },
          }}
        >
          <IconPlus />
        </ActionIcon>
      </Group>

      <Title order={4} mt="lg" mb="sm">
        Categories
      </Title>

      {isLoading ? (
        <Loader />
      ) : (
        <ScrollArea
          styles={{
            viewport: {
              height: 200,
              overflow: "auto",
            },
          }}
          scrollbarSize={0}
        >
          {categories.length > 0 ? (
            categories.map((category) => (
              <CategoryTile
                key={category["_id"]}
                name={category["name"] ?? ""}
                id={category["_id"]}
              />
            ))
          ) : (
            <div style={{ textAlign: "center", padding: "1rem" }}>
              No categories found
            </div>
          )}
        </ScrollArea>
      )}
    </Modal>
  );
};

export default CategoryModal;
