import React, { memo } from "react";
import Layout from "../container/appshell/Index";
import { useGetProfile } from "../hooks/getProfile/getProfile.query,";

const ProtectedRoute = () => {
  useGetProfile();

  return <Layout />;
};

export default memo(ProtectedRoute);
