import {
  Box,
  Center,
  Flex,
  Group,
  Loader,
  Pagination,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";
import { CustomTable } from "./CustomTable";
import { useGetPendingRequest } from "../../../hooks/leave/query/getPendingRequests.query";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import CategoryModal from "./CategoryModal";
import ThemeButton from "../../../component/button/ThemeButton";

interface IFormValues {
  fromDate: Date | null;
  toDate: Date | null;
}

export const PendingRequest: React.FC = () => {
  const [activePage, setActivePage] = useState(1);
  const [visible, setVisible] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [pagedData, setPagedData] = useState<TPageData>({
    total: 0,
  });
  const form = useForm<IFormValues>({
    initialValues: {
      fromDate: null,
      toDate: null,
    },
  });
  const {
    data: pendingRequestsData,
    isLoading: isPendingRequestLoading,
    refetch,
    isRefetching,
  } = useGetPendingRequest({
    paging: { itemPerPage: 8, page: activePage },
    search: search,
    range: { from: form.values.fromDate, to: form.values.toDate },
  });

  const pendingRequests: TLeave[] = useMemo(() => {
    if (!isPendingRequestLoading && pendingRequestsData?.data) {
      if (pendingRequestsData.pageData) {
        setPagedData(pendingRequestsData.pageData);
      }
      return pendingRequestsData.data;
    } else {
      return [] as TLeave[];
    }
  }, [isPendingRequestLoading, pendingRequestsData]);

  useEffect(() => {
    refetch();
  }, [search, activePage, form.values.fromDate, form.values.toDate]);

  const handleSearchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearch(event.target.value);
    setActivePage(1);
  };

  return (
    <Flex direction={"column"}>
      <CategoryModal onClose={() => setVisible(false)} opened={visible} />

      <Flex justify={"space-between"} align={"flex-start"}>
        <Flex align="center" mb="md">
          <TextInput
            placeholder="Search by student name or email..."
            icon={<IconSearch size={16} />}
            value={search}
            onChange={handleSearchChange}
            style={{ marginRight: 10 }}
          />
          <Group mx={10}>
            <Text>From</Text>
            <DatePickerInput
              miw={100}
              placeholder="Pick From date"
              {...form.getInputProps("fromDate")}
              clearable
            />
          </Group>
          <Group mx={10}>
            <Text>To</Text>
            <DatePickerInput
              miw={100}
              placeholder="Pick To date"
              {...form.getInputProps("toDate")}
              clearable
            />
          </Group>
        </Flex>
        <Box onClick={() => setVisible(true)}>
          <ThemeButton
            title="Add Category"
            mr={15}
            leftIcon={<IconPlus size={20} />}
          />
        </Box>
      </Flex>

      {!isPendingRequestLoading && !isRefetching ? (
        pendingRequests.length === 0 ? (
          <Center mih={"50vh"}>
            <Title order={2}>No Pending Request</Title>
          </Center>
        ) : (
          <>
            <CustomTable data={pendingRequests} itemPerPage={8} action={true} />
            {pagedData.total > 8 ? (
              <Pagination
                color={"pink"}
                my={10}
                total={Math.ceil(pagedData.total / 8)}
                value={activePage}
                onChange={setActivePage}
              />
            ) : null}
          </>
        )
      ) : (
        <Center mih={"60vh"}>
          <Loader type="bar" />
        </Center>
      )}
    </Flex>
  );
};
