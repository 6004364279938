import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const get = async (params: TAttendanceParams) => {
  const response: TServerResponse<TNoteDetail[], unknown> = await request({
    url: apiUrls.GET_NOTE_DETAILS,
    method: "GET",
    params: {
      ...params.paging,
      date: params.date,
      search: params.search,
    },
  });
  return response;
};

export const useWorkLogDetailsQuery = (params: TAttendanceParams) => {
  return useQuery(["get-worklog-details", params], () => get(params), {
    keepPreviousData: true,
  });
};
