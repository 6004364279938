import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const logout = () => {
  const response = request({
    url: apiUrls.LOGOUT,
    method: "POST",
  });
  return response;
};

export const useLogoutMutation = () => {
  return useMutation(logout);
};
