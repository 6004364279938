import { Group } from "@mantine/core";
import React, { FC, memo } from "react";
import { useGetAllEmployeeAttendance } from "../../../../hooks/all-student-attendance/query/useAllEmployeeAttendance.query";
import ExcelExport from "../../../../component/excel-export/ExportButton";

interface IAttendanceExport {
  date: Date;
  studentId: string;
}

const AttendanceExport: FC<IAttendanceExport> = ({ date, studentId }) => {
  const { data, isLoading } = useGetAllEmployeeAttendance({ date, studentId });

  const fileName =
    !isLoading && data && data.data
      ? `Attendance_${
          studentId !== "All" ? (data?.data[0]?.name ?? "") + "_" : ""
        }${new Date().toDateString()}`
      : "";

  return (
    <Group>
      <ExcelExport
        loading={isLoading}
        fileName={fileName}
        ExcelData={data?.data}
      />
    </Group>
  );
};

export default memo(AttendanceExport);
